"use strict";

// HTML Variables Storing
const burgerMenuWindowEl = document.getElementById("burger-menu-window");
const burgerMenuEl = document.getElementById("burger-menu");
const showMorePostsBtn = document.getElementById("show-more-posts");
const goToTopBtn = document.getElementById("go-to-top");
const topPage = document.getElementById("top-page");
const currentDateBannerEl = document.getElementById("current-date-banner");
const menuContainersEl = document.querySelectorAll(".menu-container");
const footerEl = document.getElementById("footer");
const seriesEl = document.getElementById("series");
const musicEl = document.getElementById("music-playlist");
const aboutMeEl = document.getElementById("about-me");
const navEl = document.querySelector("nav");
const musicMonthEl = document.getElementById("music-month");
const nbaHighlightsEl = document.getElementById("nba-highlights");
const formContactEl = document.getElementById("form-contact");
const nameInputEl = document.getElementById("name-input");
const emailInputEl = document.getElementById("email-input");
const messageInputEl = document.getElementById("message-input");
const successMessageEl = document.getElementById("success-message");
const contactEl = document.getElementById("contact");

let isBurgerMenuVisible = false;

document.addEventListener(
  "dblclick",
  function (event) {
    event.preventDefault();
  },
  { passive: false }
);

menuContainersEl.forEach((menu) => {
  menu.addEventListener("click", function (e) {
    if (e.target.dataset.id) {
      e.preventDefault();
      let targetEl = e.target.dataset.id;
      const navOffset = navEl.offsetHeight;
      const seriesPos = seriesEl.getBoundingClientRect().top;
      const musicPos = musicEl.getBoundingClientRect().top;
      const nbaHighlightsPos = nbaHighlightsEl.getBoundingClientRect().top;
      const aboutMePos = aboutMeEl.getBoundingClientRect().top;
      const contactPos = contactEl.getBoundingClientRect().top;
      const seriesOffsetPosition = seriesPos + window.pageYOffset - navOffset;
      const musicOffsetPosition = musicPos + window.pageYOffset - navOffset;
      const nbaHighlightsOffsetPosition =
        nbaHighlightsPos + window.pageYOffset - navOffset;
      const aboutMeOffsetPosition = aboutMePos + window.pageYOffset - navOffset;
      const contactOffsetPosition = contactPos + window.pageYOffset - navOffset;

      if (targetEl === "series") {
        window.scrollTo({
          top: seriesOffsetPosition,
          behavior: "smooth",
        });
      } else if (targetEl === "music") {
        window.scrollTo({
          top: musicOffsetPosition,
          behavior: "smooth",
        });
      } else if (targetEl === "nba") {
        window.scrollTo({
          top: nbaHighlightsOffsetPosition,
          behavior: "smooth",
        });
      } else if (targetEl === "about") {
        window.scrollTo({
          top: aboutMeOffsetPosition,
          behavior: "smooth",
        });
      } else if (targetEl === "contact") {
        window.scrollTo({
          top: contactOffsetPosition,
          behavior: "smooth",
        });
      }

      burgerMenuEl.style.transform = "rotateY(0)";
      burgerMenuEl.style.transition = ".5s";

      burgerMenuWindowEl.style.display = "none";
      isBurgerMenuVisible = false;
    }
  });
});

const currentTime = new Date();
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthOfTheYear = monthNames[currentTime.getMonth()];

const dayOfTheWeek = dayNames[currentTime.getDay()];
const dayNumber = currentTime.getDate();
const currentYear = currentTime.getFullYear();

currentDateBannerEl.textContent = `${monthOfTheYear} ${dayNumber}, ${currentYear}`;
musicMonthEl.textContent = `My ${monthOfTheYear}'s top 5`;

burgerMenuEl.addEventListener("click", function () {
  isBurgerMenuVisible = !isBurgerMenuVisible;

  if (isBurgerMenuVisible) {
    burgerMenuWindowEl.style.display = "block";
    burgerMenuEl.style.transform = "rotateY(180deg)";
    burgerMenuEl.style.transition = ".5s";
    burgerMenuWindowEl.style.animation = "fade-in 0.3s ease-in";
  } else {
    burgerMenuEl.style.transform = "rotateY(0)";
    burgerMenuEl.style.transition = ".5s";
    burgerMenuWindowEl.style.animation = "fade-out 0.3s ease-in";

    setTimeout(function () {
      burgerMenuWindowEl.style.display = "none";
    }, 200);
  }
});

window.addEventListener("resize", function () {
  let currentWidth = window.innerWidth;
  if (currentWidth >= 768) {
    isBurgerMenuVisible = false;
    burgerMenuEl.style.transform = "rotateY(0)";
    burgerMenuWindowEl.style.display = "none";
  }
});

goToTopBtn.addEventListener("click", function () {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

window.addEventListener("scroll", function () {
  const rect = document.body.getBoundingClientRect();

  if (rect.y <= -400) {
    goToTopBtn.style.display = "block";
    goToTopBtn.style.animation = "arrow-in 0.5s";
  } else {
    goToTopBtn.style.display = "none";
  }
});

formContactEl.addEventListener("submit", function (e) {
  successMessageEl.style.opacity = 0;

  setTimeout(function () {
    nameInputEl.value = "";
    emailInputEl.value = "";
    messageInputEl.value = "";
    successMessageEl.style.opacity = 1;
  }, 3000);
});
